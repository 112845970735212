// import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import  { jwtDecode } from 'jwt-decode';
import './RedirectPage.css';
import './Home.css';



// const BACKEND_URL = "http://localhost:8080"
const BACKEND_URL = "https://api.greenhouse.lol"

const RedirectedPage = () => {
  const [flowers, setFlowers] = useState([]);
  const [selectedFlower, setSelectedFlower] = useState(null);
  const [newFlower, setNewFlower] = useState({ name: '', description: '', type: '', typePercentage: '', thc: '', time: '' });
  const [showForm, setShowForm] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [activeFilter, setActiveFilter] = useState([])
  const [isSubmitting, setIsSubmitting] = useState(false);
  const token = localStorage.getItem('your-token-key');
  const [username, setUsername] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  if (!token) {
    window.location.href = '/';
  }
  useEffect(() => {
    // Add a class to the body when RedirectedPage is mounted
    document.body.classList.add('redirected-page-background');

    // Remove the class when the component is unmounted
    return () => {
      document.body.classList.remove('redirected-page-background');
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const token = localStorage.getItem('your-token-key');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const user = decodedToken.sub || '';
        setUsername(user);
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    }
    // const delay = 2000;
    // const fetchData = () => {
    fetch(`${BACKEND_URL}/menu?filters=${JSON.stringify(activeFilter)}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(data => {
      if (Array.isArray(data)) {
        setFlowers(data);
      } else {
        console.error('Data received is not an array:', data);
      }
    })
    .catch(error => console.error('Error fetching data:', error))
    .finally(() => {
      setIsLoading(false); // Set loading state to false regardless of success or failure
    });
    // }
    // const timeoutId = setTimeout(fetchData, delay);
    // return () => clearTimeout(timeoutId);

  }, [activeFilter]); // The empty dependency array ensures the effect runs only once on component mount

  const handleInteraction = () => {
    // Handle any interaction that should hide the error message
    setShowError(false);
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setNewFlower({ ...newFlower, [name]: value });
  };

  const handleCreateFlower = async event => {
    handleInteraction();
    event.preventDefault();
    setIsSubmitting(true);
    // Make a POST request to create a new flower
    try {
      const response = await fetch(`${BACKEND_URL}/menu/add`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newFlower),
      });

      if (response.ok) {
        // If the flower is created successfully, fetch the updated list of flowers
        window.location.reload();
               setNewFlower({ name: '', description: '' }); // Clear the form fields
        setShowForm(false); // Hide the form after successful creation
        console.log('Flower created successfully');
      } else {
        // Handle error response
        console.error('Failed to create flower');
        setErrorMessage('Failed to create flower,  Please make sure the name is properly spelled');
        setShowError(true);
      }
    } catch (error) {
      console.error('Error creating flower:', error);
      setErrorMessage('Failed to create flower,  Please make sure the name is properly spelled');
      setShowError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  function getSmallImageUrl(time) {
    switch (time) {
      case 'MORNING':
        return './sources/morning.svg';
      case 'AFTERNOON':
        return './sources/afternoon.svg';
      case 'EVENING':
        return './sources/evening.svg';
      default:
        return 'default_url';
    }
  }

  function getThcLevelDescription(thc) {
    const thcValue = parseFloat(thc);
    if (thcValue < 15) {
      return 'low';
    } else if (thcValue >= 15 && thcValue <= 25) {
      return 'normal';
    } else {
      return 'high';
    }
  }

  function getTypeName(type) {
    if (type === 'SATIVA') {
      return 'sativa';
    } else {
      return 'indica';
    }
  }

  const toggleSidebar = (event) => {
    // Check if the click target is a button within the sidebar
    if (event.target.tagName.toLowerCase() === 'button' && event.target.closest('.sidebar')) {
      return;
    }
    setCollapsed(!collapsed);
  };

  const handleFilter = (filter) => {
    if (filter === 'reset'){
      setActiveFilter([]);
    } else {
    setActiveFilter(prevFilters => {
      const updatedFilters = prevFilters.includes(filter)
        ? prevFilters.filter(f => f !== filter)
        : [...prevFilters, filter];
      console.log('Updated Filters:', updatedFilters);
      return updatedFilters;
      });
    }
  };

  return (
    <div className={`app-container ${collapsed ? 'collapsed' : ''}`}>
      {/* SIDEBAR*/}
      <div className="sidebar"  onClick={toggleSidebar}>

        {!collapsed && (
          <>
            {/* Button to toggle the visibility of the form */}
            <h1 style={{ fontSize: '1.2em' }}>Welcome, {username}!</h1>
            <label style={{ fontSize: '0.8em' }}>Add a flower</label>
            <button onClick={() => {
              setShowForm(!showForm);
              setNewFlower({ name: '', description: '' }); // Clear the form fields
            }} className="new-flower-button">
            
            <img src='./sources/add.png' alt="add" className="add-image-button"/>
            </button>
            
            {/* Filter Buttons */}
          
            <h1 style={{ fontSize: '1em', marginTop: '100px'}}>FILTERS 
            <button onClick={() => handleFilter('reset')} className='reset'>Reset</button>
            </h1> 
            <p>Types</p>
            <button onClick={() => handleFilter('SATIVA')} className={activeFilter.includes('SATIVA')  ? 'active-filter' : ''}>Sativa</button>
            <button onClick={() => handleFilter('INDICA')} className={activeFilter.includes('INDICA') ? 'active-filter' : ''}>Indica</button>
            <p>Best consumed</p>
            <button onClick={(e) =>{
                e.stopPropagation();
                handleFilter('MORNING');
              }}  className={activeFilter.includes('MORNING') ? 'active-filter' : ''}>
              <img src='./sources/morning.svg' alt="Morning" className="small-image-button "/>
            </button>
            <button onClick={(e) =>{
                e.stopPropagation(); 
                handleFilter('AFTERNOON');
              }} className={activeFilter.includes('AFTERNOON') ? 'active-filter' : ''}>
              <img src='./sources/afternoon.svg' alt="Afternoon" className="small-image-button "/>
            </button>
            <button onClick={(e) =>{
               e.stopPropagation();
               handleFilter('EVENING');
              }} className={activeFilter.includes('EVENING') ? 'active-filter' : ''}>
              <img src='./sources/evening.svg' alt="Evening" className="small-image-button "/>
            </button>
            <p>Strength</p>
            <button onClick={() => handleFilter('low')} className={activeFilter.includes('low') ? 'active-filter' : ''}>Low</button>
            <button onClick={() => handleFilter('normal')} className={activeFilter.includes('normal') ? 'active-filter' : ''}>Normal</button>
            <button onClick={() => handleFilter('high')} className={activeFilter.includes('high') ? 'active-filter' : ''}>High</button>
          </>
        )}
      </div>

      {/* MAIN CONTENT*/}
      <div className="main-content">
        <div className="flower-container">
          {isLoading && <div className="loading-indicator">Loading...</div>}
          
          {/* Modal to add new flower */}
          {showForm && (
            <div className={`modal ${showForm ? 'active' : ''}`} onClick={() => {setShowForm(false); handleInteraction();}}>
              <div className="error-message" style={{ display: showError ? 'block' : 'none' }}>
                {errorMessage}
              </div>
              <form onSubmit={handleCreateFlower} className="new-flower-form" onClick={(e) => e.stopPropagation()}>
                <h2>Add a New Flower</h2>
                 {/* Add other form fields as needed */}
                <label>
                  Name:
                  <input type="text" name="name" value={newFlower.name} onChange={handleInputChange} required />
                </label>
                <button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Creating...' : 'Create Flower'}
                </button>
              </form>
            </div>
          )}

           {/* Modal to show flower details */}
           {showDetails && selectedFlower && (
            <div className={`modal ${showDetails ? 'active' : ''}`} onClick={() => setShowDetails(false)}>
              <form className="flower-details-form" onClick={(e) => e.stopPropagation()}>
                <h2>{selectedFlower.name}</h2>
                <p>Hey check this out, in here we can display a small description and common usage effects about the {selectedFlower.name} strain. 
                  Isn't it great? Oh boy i sure am hyped to have all that info here.</p>
                  
                <a href={selectedFlower.description} target="_blank" rel="noopener noreferrer">
                 More info
                </a>
              </form>
            </div>
          )}
          <div  className="banner">

            <h1>{username}'s Garden</h1>
          </div>
          {/* Flower cards*/}
          <div className="flower-cards">
            {flowers.map(flower => (
              <div key={flower.id} onClick={() => {
                setSelectedFlower(flower);
                setShowDetails(!showDetails);
              }}target="_blank" rel="noopener noreferrer" className="flower-card no-link-style">
                <div className="card-content">
                  <img src={flower.imageUrl} alt={flower.name} className="main-image" />
                  <img src={getSmallImageUrl(flower.time)} alt={flower.time} className="small-image" />
                  <h2>{flower.name}</h2>
                  <div className="flower-details">
                    <div className="column">
                      <h3>{flower.typePercentage + ' ' + flower.type}</h3>
                      <div className={`progress-bar ${getTypeName(flower.type)}`}>
                        <div
                          className={`progress-bar-fill ${getTypeName(flower.type)}`}
                          style={{ width: `${Math.min(parseInt(flower.typePercentage), 100)}%` }}
                        ></div>
                      </div>
                    </div>
                    <div className="column">
                      <h3>{flower.thc + '  THC'}</h3>
                      <p className={`thc-level ${getThcLevelDescription(flower.thc)}`}>{getThcLevelDescription(flower.thc).toUpperCase()}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>  
  );
};


const Home = () => {
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [user, setUser] = useState({ username: '', password: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  const handleInteraction = () => {
    // Handle any interaction that should hide the error message
    setShowError(false);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const handleRegisterUser = async (event) => {    
    setIsLoading(true);
    setIsSubmitting(true);
    handleInteraction();
    event.preventDefault();

    // Make a POST request to register a new user
    try {
      const response = await fetch(`${BACKEND_URL}/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });

      if (response.ok) {
        // If the user is registered successfully, close the form
        setShowRegisterForm(false);
        console.log('User registered successfully');
      } else {
        // Handle error response
        console.error('Failed to register user');
        setErrorMessage('Username already in use. Please try again.');
        setShowError(true);
      }
    } catch (error) {
      console.error('Error registering user:', error);
      setErrorMessage('An error occurred while registering. Please try again.');
      setShowError(true);
    } finally {
      setIsLoading(false);
      setIsSubmitting(false);
    }
  };

  const handleLoginInputChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };
  
  const handleLoginUser = async (event) => {
    setIsLoading(true);
    setIsSubmitting(true);
    handleInteraction();
    event.preventDefault();
  
    // Make a POST request to log in the user
    try {
      const response = await fetch(`${BACKEND_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });
      console.log(response);
      if (response.ok) {
        const token = await response.text();
        localStorage.setItem('your-token-key', token);
        window.location.href = '/garden';
      } else {
        console.error('Failed to log in user');
        setErrorMessage('Invalid username or password. Please try again.');
        setShowError(true);
      }
    } catch (error) {
      console.error('Error logging in user:', error);
      setErrorMessage('An error occurred while logging in. Please try again.');
      setShowError(true);
    } finally {
      setIsLoading(false);
      setIsSubmitting(false);
    }
  };
  
  return (
    <div className="home-container-all">
       {isLoading && <div className="loading-indicator-home">Loading...</div>}
      <div className='home-container'>
        <h1>Welcome to the Garden!</h1>
      </div>

      <button onClick={() => {
        setShowLoginForm(!showLoginForm);
        }} className="enter-button">
        {showLoginForm ? 'Cancel' : 'to the garden'}
      </button>

     {/* Modal for user login form */}
     {showLoginForm && (
        <div className={`modal ${showLoginForm ? 'active' : ''}`} onClick={() => {setShowLoginForm(false); handleInteraction();}}>
          <div className="error-message" style={{ display: showError ? 'block' : 'none' }}>
            {errorMessage}
          </div>
          <form onSubmit={handleLoginUser} className="user-login-form" onClick={(e) => e.stopPropagation()} noValidate>
            <h2 className='user-login-form-other'>Login</h2>
            <label>
              Username:
              <input type="text" name="username" value={user.username} onChange={handleLoginInputChange} required />
            </label>
            <label>
              Password:
              <input type="password" name="password" value={user.password} onChange={handleLoginInputChange} required />
            </label>
            <button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Loging in...' : 'Login'}
                </button>
            <p>
              <label>
                Don't have an account? Click
              </label>
              <label onClick={(e) => {
                e.stopPropagation();
                handleInteraction(e);
                setShowRegisterForm(!showRegisterForm);
              }} className="new-user-click">
                &nbsp;here&nbsp;
              </label>
              <label>
                to register
              </label>
            </p>
          </form>
        </div>
      )}

      {/* Modal for the register form */}
      {showRegisterForm && (
        <div className={`modal ${showRegisterForm ? 'active' : ''}`} onClick={() => {setShowRegisterForm(false); handleInteraction();}}>
          <div className="error-message" style={{ display: showError ? 'block' : 'none' }}>
            {errorMessage}
          </div>
          <form onSubmit={handleRegisterUser} className="user-registration-form" onClick={(e) => e.stopPropagation()}>
            <h2 className='user-registration-form-other'>Register User</h2>
            <label>
              Username:
              <input type="text" name="username" value={user.username} onChange={handleInputChange} required />
            </label>
            <label>
              Password:
              <input type="password" name="password" value={user.password} onChange={handleInputChange} required />
            </label>
            <button type="submit" disabled={isSubmitting}>
                  {isSubmitting ? 'Registering...' : 'Register'}
                </button>
          </form>
        </div>
      )}
    </div>
  );
};


const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/garden" element={<RedirectedPage />} />
      </Routes>
      {/* Redirect /home to / */}
      {/* <Navigate from="/home" to="/" /> */}
    </Router>
  );
};

export default App; 
